import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Img from 'gatsby-image';

import Layout from '../../components/layout/layout';
import Seo from '../../components/seo/seo';
import FreedomBanner from '../../components/freedomBanner/freedomBanner';
import SectionHero from '../../components/wordPress/sections/sectionHero/sectionHero';

const WhatWeTreatPage = ({ data }) => {
  // Map ACF group columnContent to variable
  const page = data.wpPage.pageWhatWeTreat;

  return (
    <Layout>
      <Seo
        canonical={`https://www.essentialbehavioral.com/what-we-treat/`}
      />

      <>
        <SectionHero
          buttonDefaults={data.wp.globalContent.globalButtons.buttonSettings}
          sectionContent={page.whatWeTreatHeader}
        />

        <section className={`py-5`}>
          <Container>
            <Row className={`justify-content-center text-center`}>
              <Col xs={11} md={8}>
                <div className="section-headlines">
                  <p>We treat substance use disorders with a broad range of co-occurring mental health challenges including anxiety, depression, personality disorders and can even manage secondary eating disorders. Starting with a biopsychosocial assessment, we identify the challenges facing each client individually and create a personalized treatment plan that will address the addiction piece and other identified needs. Every program focuses on giving clients the tools to confidentially manage life after treatment, complete with comprehensive aftercare and alumni support.</p>
                </div>
              </Col>
            </Row>

            <Row className={`justify-content-center`}>
              <Col xs={12} md={4}
                className={`col`}
              >
                <Card>
                  <Img
                    fluid={
                      page.disorderTypes.columns[0].layout[0].image.localFile.childImageSharp.fluid
                    }
                    alt={page.disorderTypes.columns[0].layout[0].image.altText}
                    className={`card-img-top`}
                  />
                  <Card.Body>
                    <Card.Title>Co-occurring Disorders</Card.Title>
                    <Card.Text>Disorders that are present in addition to substance abuse issues such as anxiety, depression, personality disorders, bipolar disorder, disordered eating, and many more.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4}
                className={`col`}
              >
                <Card>
                  <Img
                    fluid={
                      page.disorderTypes.columns[1].layout[0].image.localFile.childImageSharp.fluid
                    }
                    alt={page.disorderTypes.columns[1].layout[0].image.altText}
                    className={`card-img-top`}
                  />
                  <Card.Body>
                    <Card.Title>Substance Use Disorders</Card.Title>
                    <Card.Text>Professional treatment for alcohol, opioids, prescription drugs and other substances.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <hr />

        <section className={`py-5`}>
          <Container>
            <Row className={`justify-content-center text-center`}>
              <Col xs={11} md={8}>
                <h2>What Are Co-Occurring Disorders?</h2>
                <p>Mental health issues that occur simultaneously with substance use disorders are called co-occurring disorders. About half of people who struggle with mental health disorders will also struggle with substance use and vice-versa. Co-occurring disorders can also refer to having multiple mental health issues, such as anxiety alongside disordered eating, or struggling with multiple substances. We understand that few people experience a single, neatly identified disorder. Our treatment approach accounts for this, and we have made co-occurring disorders a cornerstone of what we treat.</p>
                <h2>Certification</h2>
                <p>An accredited substance abuse treatment facility, Clean &amp; Sober Detox is also certified to treat co-occurring mental health disorders with substance abuse disorders. Our staff has deep experience addressing both addiction and mental health. We see the treatment of co-occurring disorders as part of our mission to help clients reclaim their whole physical and mental health.</p>
              </Col>
            </Row>
          </Container>
        </section>

        <hr />

        <section className={`py-5 text-center`}>
          <Container>
            <Row className="justify-content-center section-content mb-4">
              <Col xs={11} md={8}>
                <p>At Clean &amp; Sober Detox, our treatment is rooted in the philosophy that recovery is a lifelong journey. We have built each program to address practical life challenges, while giving our clients ongoing support and encouragement long after leaving our care.</p>
                <p>Our intake process starts with a personalized assessment that allows us to get to know you so we can develop a unique plan based on your medical history, personality and goals. Our continuing care and alumni services ensure that you are never alone on your journey.</p>
              </Col>
            </Row>
          </Container>
        </section>

        <FreedomBanner />
      </>
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(databaseId: { eq: 1003 }) {
      title
      seo {
        metaTitle
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      pageWhatWeTreat {
        whatWeTreatHeader {
          fieldGroupName
          content
          htmlClass
          background {
            backgroundType
            backgroundImage {
              localFile {
                ...imageFluidLarge
              }
            }
            backgroundVideo {
              localFile {
                publicURL
              }
            }
            backgroundVideoCover {
              localFile {
                publicURL
              }
            }
          }
          buttonLayout {
            showButton
            overrideButtonSettings
            buttonSettings {
              buttonText
              linkType
              internalLink {
                ... on WpPage {
                  uri
                }
                ... on WpPost {
                  uri
                }
              }
              externalLink
              htmlClass
            }
          }
        }
        disorderTypes {
          content
          columns {
            fieldGroupName
            breakpoints {
              extraLarge
              extraSmall
              fieldGroupName
              large
              medium
              small
            }
            layout {
              ... on WpPage_Pagewhatwetreat_DisorderTypes_columns_Layout_Card {
                fieldGroupName
                text
                title
                image {
                  altText
                  localFile {
                    ...imageFluidSmall
                  }
                }
                buttonLayout {
                  showButton
                  overrideButtonSettings
                  buttonSettings {
                    buttonText
                    linkType
                    htmlClass
                    externalLink
                    internalLink {
                      ... on WpPost {
                        uri
                      }
                      ... on WpPage {
                        uri
                      }
                    }
                  }
                }
              }
            }
          }
        }
        cooccurringDisorders
        treatmentFaq {
          content
          accordionLayout {
            groups {
              headline
              content
            }
          }
        }
      }
    }
    wp {
      globalContent {
        globalButtons {
          buttonSettings {
            buttonText
            linkType
            internalLink {
              ... on WpPost {
                id
                uri
              }
              ... on WpPage {
                id
                uri
              }
            }
            externalLink
            htmlClass
          }
        }
      }
    }
  }
`;
export default WhatWeTreatPage;