import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import BackgroundImage from 'gatsby-background-image';

import Styles from './sectionHero.styles';
import * as Globals from '../../../../../globals';
import Wysiwyg from '../../wysiwyg/wysiwyg';
import LayoutButton from '../../layouts/layoutButton/layoutButton';

const SectionHeroContent = ({ sectionContent, buttonDefaults, whiteText }) => {
  return (
    <Container style={{ zIndex: 1, position: 'relative' }}>
      <Row className={`justify-content-center`}>
        <Col xs={11} md={8} className={whiteText && `text-white`}>
          <Wysiwyg html={sectionContent.content} />

          {sectionContent.buttonLayout.showButton && (
            <div className={`mt-3`}>
              <LayoutButton
                buttonSettings={
                  sectionContent.buttonLayout.overrideButtonSettings !== null
                    ? sectionContent.buttonLayout.buttonSettings
                    : null
                }
                buttonDefaults={buttonDefaults}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const SectionHero = ({ sectionContent, buttonDefaults }) => {
  let backgroundFluidImageStack = [
    sectionContent.background.backgroundImage?.localFile.childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Styles className={`${sectionContent.htmlClass} jumbotron text-center`}>
      {sectionContent.background.backgroundType === 'image' && (
        <BackgroundImage
          fluid={backgroundFluidImageStack}
          className="section-background"
        >
          <SectionHeroContent
            sectionContent={sectionContent}
            buttonDefaults={buttonDefaults}
            whiteText={true}
          />
        </BackgroundImage>
      )}

      {sectionContent.background.backgroundType === 'video' && (
        <>
          {/* <SectionHeroContent
            sectionContent={sectionContent}
            buttonDefaults={buttonDefaults}
            whiteText={true}
          /> */}

          <Container style={{ zIndex: 1, position: "relative" }}>
            <Row className={`justify-content-center`}>
              <Col xs={8} className={`text-white mb-3`}>
                <h1>Clean &amp; Sober Detox</h1>
                <pre>Nurturing care through every step toward lifelong recovery.</pre>
              </Col>
            </Row>

            {sectionContent.buttonLayout.showButton && (
              <LayoutButton
                buttonSettings={
                  sectionContent.buttonLayout.overrideButtonSettings !== null
                    ? sectionContent.buttonLayout.buttonSettings
                    : null
                }
                buttonDefaults={buttonDefaults}
              />
            )}
          </Container>

          <div
            className="video-overlay"
            style={{ background: Globals.backgroundOverlay }}
          />
          <div
            className="video-background"
            dangerouslySetInnerHTML={{
              __html: `
          <video
            autoPlay
            loop
            muted
            playsInline
            poster=${sectionContent.background.backgroundVideoCover.localFile.publicURL}
          >
            <source
              src=${sectionContent.background.backgroundVideo.localFile.publicURL}
              type="video/mp4"
            />
          </video>
        `,
            }}
          />
        </>
      )}

      {sectionContent.background.backgroundType === 'none' && (
        <SectionHeroContent
          sectionContent={sectionContent}
          buttonDefaults={buttonDefaults}
        />
      )}
    </Styles>
  );
};

SectionHero.propTypes = {
  sectionContent: PropTypes.object.isRequired,
  buttonDefaults: PropTypes.object.isRequired,
};

export default SectionHero;
