import React from 'react';
import PropTypes from 'prop-types';

import Styles from './wysiwyg.styles';

const Wysiwyg = ({ html }) => (
  <Styles dangerouslySetInnerHTML={{ __html: html }} />
);

Wysiwyg.propTypes = {
  html: PropTypes.node.isRequired,
};

export default Wysiwyg;
