import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const LayoutButton = ({ buttonDefaults, buttonSettings, stretchedLink }) => (
  <>
    {buttonSettings !== null && (
      <>
        {buttonSettings.linkType === 'internal' && (
          <Link
            to={buttonSettings.internalLink.uri}
            className={
              stretchedLink
                ? `${buttonSettings.htmlClass} stretched-link`
                : `${buttonSettings.htmlClass} ${buttonDefaults.htmlClass}`
            }
          >
            {buttonSettings.buttonText}
          </Link>
        )}

        {buttonSettings.linkType === 'external' && (
          <a
            href={buttonSettings.externalLink}
            className={
              stretchedLink
                ? `${buttonSettings.htmlClass} stretched-link`
                : `${buttonSettings.htmlClass} ${buttonDefaults.htmlClass}`
            }
          >
            {buttonSettings.buttonText}
          </a>
        )}
      </>
    )}

    {buttonSettings == null && (
      <>
        {buttonDefaults.linkType === 'internal' && (
          <Link
            to={buttonDefaults.internalLink.uri}
            className={
              stretchedLink
                ? `${buttonDefaults.htmlClass} stretched-link`
                : `${buttonDefaults.htmlClass}`
            }
          >
            {buttonDefaults.buttonText}
          </Link>
        )}

        {buttonDefaults.linkType === 'external' && (
          <a
            // href={buttonDefaults.externalLink}
            href="tel:(866) 209-6600"
            className={
              stretchedLink
                ? `${buttonDefaults.htmlClass} stretched-link`
                : `${buttonDefaults.htmlClass}`
            }
          >
            {/* {buttonDefaults.buttonText} */}
            Call (866) 209-6600
          </a>
        )}
      </>
    )}
  </>
);

LayoutButton.propTypes = {
  buttonDefaults: PropTypes.object.isRequired,
  buttonSettings: PropTypes.object,
  stretchedLink: PropTypes.bool,
};

export default LayoutButton;
